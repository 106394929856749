import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber';

/**
 * return null if the number is not formattable
 */
const normalizePhoneNumber = (phoneNumber: string, countryCode = '') => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  //todo: change the country code, don't hard code it.
  let phoneNumberObj;

  if (!countryCode) {
    return phoneNumber;
  }
  
  if (countryCode.length) {
    phoneNumberObj = phoneNumber && phoneUtil.parse(phoneNumber, countryCode);
  }

  if (phoneNumberObj == null) return null;

  let formattedResult: string;

  const formattedNumberWithLeaderPlus = phoneUtil.format(phoneNumberObj, PNF.E164) as string;
  if (formattedNumberWithLeaderPlus.startsWith('+'))
    formattedResult = formattedNumberWithLeaderPlus.replace('+', '');
  else formattedResult = formattedNumberWithLeaderPlus;

  return formattedResult;
};

export const formatPhoneNumber = ({
  phoneNumber,
  normalizedPhoneNumber,
  userRegion = '',
}: {
  phoneNumber: string;
  normalizedPhoneNumber: string;
  userRegion: string;
}) => {
  const isNotMobileNumber = phoneNumber?.replace('+', '').replace(' ', '').length < 7;

  if (isNotMobileNumber) {
    return phoneNumber;
  } else {
    try {
      const unformatted = normalizedPhoneNumber.startsWith('+')
        ? normalizedPhoneNumber
        : `+${normalizedPhoneNumber}`;
      const phoneUtil = PhoneNumberUtil.getInstance();
      const phoneNumberObj = phoneUtil.parse(unformatted, '');

      if (phoneNumberObj == null) {
        return normalizedPhoneNumber;
      }

      const region = phoneUtil.getRegionCodeForCountryCode(phoneNumberObj.getCountryCode());
      const isValidForRegion = phoneUtil.isValidNumberForRegion(phoneNumberObj, region);

      if (!isValidForRegion) {
        return normalizedPhoneNumber;
      }

      const isUserRegionNumber = region === userRegion;

      const format = isUserRegionNumber ? PNF.NATIONAL : PNF.INTERNATIONAL;

      return phoneUtil.format(phoneNumberObj, format);
    } catch {
      return phoneNumber;
    }
  }
};

export default normalizePhoneNumber;
